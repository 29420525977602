<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark font-poppins">POS Product Import</span>
      </h3>
    </div>
    <!--end::Header-->
    <div class="row card-body pt-0 pb-3">
      <div class="col-md-12">
        <div class="row">
          <div class="form-group col-md-4">
            <label for=""><b>Products</b></label>
              <b-form-file
              v-model="productFile"
              placeholder="Choose a file to upload"
              drop-placeholder="Drop file here..."
            ></b-form-file>
            <button
              v-on:click="save"
              v-if="productFile != null"
              class="mt-5 btn btn-success">
              <i class="flaticon2-add-1"></i>Upload Products
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import "@/assets/sass/pages/wizard/wizard-1.scss";
</style>

<script>
  import ApiService from "@/core/services/api.service";
  import Swal from "sweetalert2";
  import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

  export default {
    name: "Setting",
    components: {
    },
    data() {
      return {
        productFile : null,
      };
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "POS Import" },
        { title: "Settings" }
      ]);
    },
    methods: {
      save() {
        let formData = new FormData();
        if (this.productFile != null)
        {
          formData.append('file', this.productFile);
          ApiService.fileUpload(
            'business/import/pos',
            formData
          )
          .then(({ data }) => {
            if (data == 1) {
              Swal.fire("Success", "File Uploaded Successfully", "success");
              this.get();
            } else {
              Swal.fire("Error", data, "error");
            }
          })
          .catch(({ response }) => {
              console.log(response);
          });
        }
      },
    }
  };
</script>
